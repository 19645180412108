<template>
  <article class="py-8" v-if="settings.version">
    <div class="mb-4 flex flex-wrap gap-1 text-xs md:text-sm">
      <OrbitAuthor
        class="mr-1"
        :params="{ style: 'AuthorAvatar', id: post.author }"
        v-if="post.author"
      />
      <OrbitAuthor
        class="mt-1"
        :params="{ style: 'AuthorName', id: post.author }"
        v-if="post.author"
      />
      <div class="mt-1" v-if="getFirstCategory(post)">
        <span class="mr-1">in</span>
        <OrbitTerm
          class="underline"
          :params="{ style: 'TermName', id: getFirstCategory(post) }"
        />
      </div>
    </div>
    <div
      class="flex"
      :class="[hasFeaturedImage(post) ? 'justify-between' : null, post.type]"
    >
      <router-link
        :to="getPostLink(post)"
        class="thumbnail-bg h-32 w-1/3 rounded bg-gray"
        v-if="hasFeaturedImage(post)"
      >
        <PostFeaturedImage
          :media_id="post.featured_media"
          :key="post.featured_media"
        />
      </router-link>

      <div
        class="post-content flex flex-col justify-between"
        :class="[hasFeaturedImage(post) ? 'w-2/3 pl-6' : 'w-full flex-1']"
      >
        <div>
          <router-link
            :to="getPostLink(post)"
            class="font-bold leading-5 md:leading-6 text-lg md:text-xl mt-0"
          >
            <div class="line-clamp-3" v-html="post.title.rendered"></div>
          </router-link>

          <div class="text-mid-gray additional-info post-excerpt text-sm my-2">
            <div
              class="line-clamp-2"
              v-if="post.excerpt"
              v-html="post.excerpt.rendered"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="post-meta mt-4 flex relative flex-wrap gap-4 md:gap-8 text-xs md:text-sm text-gray w-full"
    >
      <IconBtn
        v-if="isFeatured()"
        icon="checked-badge"
        :label="formatDate(post.date)"
      />
      <div class="mt-1 md:mt-0" v-html="formatDate(post.date)" v-else></div>
      <IconBtn
        v-if="post.like && post.like.total"
        icon="liked"
        :label="showLikesCount ? formatTotal(post.like.total) : null"
      />
      <IconBtn
        v-if="post.total_comments"
        icon="commented"
        :label="formatTotal(post.total_comments)"
      />
      <router-link
        class="absolute top-0 left-0 block w-full h-full"
        :to="getPostLink(post)"
      ></router-link>
    </div>
  </article>
</template>

<script>
//import Icon from "@/components/Icons.vue";
import IconBtn from "@/components/IconBtn.vue";
import API from "@/api";
import Util from "@/lib/Util";
import OrbitTerm from "@/lib/OrbitTerm.vue";
import OrbitAuthor from "@/lib/OrbitAuthor.vue";
import PostFeaturedImage from "@/templates/post/FeaturedImage.vue";

import Settings from "@/lib/Settings";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    post: Object,
  },
  components: {
    OrbitAuthor,
    OrbitTerm,
    IconBtn,
    PostFeaturedImage,
    //Icon
  },
  methods: {
    truncate: (text, textLength = 80) => Util.truncate(text, textLength),
    getPostLink: (post) => Util.getPostLink(post),
    getFirstCategory: (post) =>
      post.categories && post.categories.length ? post.categories[0] : 0,
    getFeaturedImage: (post) =>
      post.featured_image ? post.featured_image : "",
    hasFeaturedImage: (post) => Util.hasFeaturedImage(post),
    getAuthor() {
      return API.requestUser(this.post.author);
    },
    formatDate: (dateString) => Util.formatDate(dateString),
    formatTotal: (total) => total.toString(),
  },
  setup(props) {
    const { settings } = Settings();
    const store = useStore();

    const isFeatured = () =>
      props.post &&
      props.post.categories &&
      props.post.categories.length &&
      Array.isArray(props.post.categories) &&
      props.post.categories.includes(settings.value.featured_category)
        ? true
        : false;

    const canUserModify = computed(
      () => props.post?.author === parseInt(store.state?.localSettings?.id)
    );

    const showLikesCount = computed(
      () => parseInt(props.post?.like?.total) > 100 || canUserModify.value
    );

    return {
      settings,
      isFeatured,
      showLikesCount,
    };
  },
};
</script>

<style scoped>
.post-list .author-avatar {
  @apply w-6 h-6 md:w-8 md:h-8;
}

.thumbnail-bg {
  flex: 0 0 180px;
  height: 120px;
  @apply overflow-hidden;
}

article.video .thumbnail-bg {
  @apply relative;
}
article.video .thumbnail-bg::before,
article.video .thumbnail-bg::after {
  @apply absolute top-1/2 left-1/2 z-10;
  transform: translate(-50%, -50%);
  content: "";
}
article.video .thumbnail-bg::before {
  @apply border-2 border-white rounded-full w-10 h-10;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
  background: rgba(0, 0, 0, 0.42);
}

article.video .thumbnail-bg::after {
  @apply w-0 h-0;
  border: 8px solid transparent;
  border-left: 15px solid #fff;
  margin-left: 6px;
}

.post-content {
  flex-basis: calc(100% - 180px);
}

article.video .additional-info,
.post-list-two-grid .additional-info {
  @apply hidden;
}

article.post .post-excerpt:deep(p) {
  @apply m-0;
}

@media (max-width: 768px) {
  .thumbnail-bg {
    flex-basis: 100px;
    height: 100px;
  }

  .post-content {
    flex-basis: calc(100% - 100px);
  }
}
</style>
