<template>
  <div
    class="whitespace-nowrap overflow-auto space-x-2 pb-4 mt-4 sm:space-x-6"
    v-if="posts.length"
  >
    <article
      v-for="post in posts"
      :key="post"
      class="inline-block p-4 max-w-xs w-full bg-white mb-2 rounded"
    >
      <div class="mb-4 flex flex-wrap gap-1 text-xs md:text-sm">
        <OrbitAuthor
          class="mr-1 h-10 w-10"
          :params="{ style: 'AuthorAvatar', id: post.author }"
          v-if="post.author"
        />
        <div class="text-sm text-gray">
          <OrbitAuthor
            class="mt-1"
            :params="{ style: 'AuthorName', id: post.author }"
            v-if="post.author"
          />
          <div class="font-thin text-xs" v-html="formatDate(post.date)"></div>
        </div>
      </div>
      <router-link :to="getPostLink(post)">
        <div class="font-bold mb-4 text-lg whitespace-normal h-16">
          <div class="line-clamp-2" v-html="post.title.rendered"></div>
        </div>
        <div class="flex gap-2 text-xs mb-2 h-4 text-gray">
          <div v-if="showCount(post.total_comments)">
            {{ post.total_comments }} Comments
          </div>
          <div v-if="showCount(post.poll_votes)">
            {{ post.poll_votes }} Votes
          </div>
          <div
            v-if="
              post.like &&
              post.like.total &&
              showCount(parseInt(post.like.total))
            "
          >
            {{ post.like.total }} Likes
          </div>
        </div>

        <div class="flex gap-2 text-xs text-gray">
          <IconBtn icon="like" />
          <IconBtn icon="comment" />
          <IconBtn icon="share" />
        </div>
      </router-link>
    </article>
  </div>
</template>

<script>
import Util from "@/lib/Util";
import IconBtn from "@/components/IconBtn.vue";
import OrbitAuthor from "@/lib/OrbitAuthor.vue";

export default {
  props: {
    posts: Array,
    status: String,
  },
  components: {
    IconBtn,
    OrbitAuthor,
  },
  setup() {
    const getPostLink = (post) => Util.getPostLink(post);

    const formatDate = (dateString) => Util.formatDate(dateString);

    const showCount = (count) => count && count > 100;

    return {
      getPostLink,
      formatDate,
      showCount,
    };
  },
};
</script>
