<template>
  <div class="term-tabs-wrapper">
    <TermTabsItems
      :tabs="modifiedItems"
      :style="style"
      :categories_exclude="categories_exclude"
    />
  </div>
</template>

<script>
import TermTabsItems from "../components/TermTabsItems.vue";
import { ref, computed, watchEffect } from "vue";
import API from "@/api";
import OrbitQuery from "@/lib/OrbitQuery";

export default {
  name: "TermTabs",
  props: {
    params: Object,
  },
  components: {
    TermTabsItems,
  },

  setup(props) {
    const terms__in = ref(props.params.terms__in || "");
    const latestFlag = ref(props.params.latest || 1);
    const style = ref(props.params.style || "PostList");
    const categories_exclude = ref(props.params.categories_exclude);
    const items = ref([]);
    const dataLoaded = ref(false);

    if (terms__in.value) {
      const requestAPI = () =>
        API.requestTerms("categories", {
          include: terms__in.value,
          orderby: "include",
        });

      const { items: fetchedItems, status } = OrbitQuery({}, requestAPI);

      watchEffect(() => {
        if (status.value === "success") {
          items.value = fetchedItems.value || [];
          dataLoaded.value = true;
        }
      });
    }

    const modifiedItems = computed(() => {
      if (!dataLoaded.value && terms__in.value) return [];

      const currentItems = [...items.value];

      if (latestFlag.value === "1") {
        currentItems.unshift({ id: 0, name: "Latest" });
      }
      return currentItems;
    });

    return {
      items,
      terms__in,
      modifiedItems,
      style,
      categories_exclude,
    };
  },
};
</script>
